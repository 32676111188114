import React from "react";
import "../common.css";
import CommonHeadings from "./commonHeadings";
import { MyEducation, MyExperience, MySkills } from "./datas";
import LinearWithValueLabel from "./progressBar";
import CvBtn from "./cvBtn";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const Resume = () => {
  return (
    <>
      <div id="resume" className="resumeMain">
        <CommonHeadings commonheadingbgtxt="SUMMARY" commonheading="Resume" />
        <div className="resumeContentDiv">
          <div className="myEducation" data-aos="fade-right">
            <h2>My Education</h2>

            {MyEducation?.map((myedu) => {
              return (
                <div className="myEducationChild" data-aos="fade-right">
                  <span>{myedu.year}</span>
                  <h3>{myedu.feild}</h3>
                  <p className="clgLoca">{myedu.location}</p>
                  <p className="feildDetail">{myedu.text}</p>
                </div>
              );
            })}
          </div>
          <div className="myExperience" data-aos="fade-left">
            <h2>My Experience</h2>
            {MyExperience?.map((myexp) => {
              return (
                <div className="myEducationChild" data-aos="fade-left">
                  <span>{myexp.year}</span>
                  <h3>{myexp.feild}</h3>
                  <p className="clgLoca">{myexp.location}</p>
                  <p className="feildDetail">{myexp.text1}</p>
                  <p className="feildDetail">{myexp.text2}</p>
                  <p className="feildDetail">{myexp.text3}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mySkill">
          <h2>My Skills</h2>
          <div className="mySkillChilds">
            {MySkills?.map((mySki) => {
              return (
                <div className="mySkillChild" data-aos="fade-up">
                  <p className="mySkillChildHeading">{mySki.field}</p>
                  <LinearWithValueLabel limit={mySki.value} />
                </div>
              );
            })}
          </div>
          <div className="resumeBtn">
            <CvBtn inText="Download CV" link="" target="_blank" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Resume;
