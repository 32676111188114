import React from "react";
import "../common.css";
import Counter from "./counter";
import CvBtn from "./cvBtn";
import CommonHeadings from "./commonHeadings";
import { MyDetails } from "./datas";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const About = () => {
  return (
    <>
      <div id="aboutSection" className="aboutMain">
        <CommonHeadings
          commonheadingbgtxt="ABOUT ME"
          commonheading="Know Me More"
        />
        <div className="aboutContentDiv">
          <div className="aboutContentLeft" data-aos="fade-left">
            <h2 className="abtMyDetail">
              I'm <span style={{ color: "#20c997 " }}> Sharique Mangani, </span>
              a Web Developer
            </h2>
            <p>
              As a Front-End Engineer with 1+ years of experience in the market,
              I specialize in crafting visually stunning and highly functional
              user interfaces. Proficient in HTML, CSS, and JavaScript, I excel
              in leveraging modern frameworks like ReactJS and NextJS to deliver
              seamless web applications. I bring a keen understanding of user
              experience principles to the table, creating intuitive interfaces
              that enhance overall user satisfaction.
            </p>
            <p>
              As a dedicated Front-End Engineer, I am driven to contribute my
              skills and expertise to create innovative and engaging digital
              experiences.
            </p>
          </div>
          <div className="aboutContentRight" data-aos="fade-right">
            {MyDetails?.map((values) => {
              return (
                <p>
                  <strong>{values.content} </strong>
                  <span style={{ color: `${values.color}` }}>
                    {values.text}
                  </span>
                </p>
              );
            })}
            <CvBtn inText="Download CV" link="#" target="_blank" />
          </div>
        </div>
        <Counter />
      </div>
    </>
  );
};

export default About;
